body {
  margin: 0;
  padding: 0;
  background-image: url('./background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-family: 'Roboto', sans-serif;
}

.container {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  color: #fff;
  text-align: center;
}

.title {
  font-size: 3rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin-bottom: 2rem;
}

.main-image {
  max-width: 80%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.description {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.links-container {
  display: flex;
  gap: 2rem;
}

.link {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-bottom: 2rem;
}

.link:hover {
  background-color: rgba(0, 0, 0, 0.7);
}